import React, { Component, useState } from 'react'
import styled, { css, onDesktop, onMobile } from './styled-extended'
import { omit, capitalize, range, isEmpty } from 'lodash-es'
import classnames from 'classnames'
import handleViewport from 'react-in-viewport'
import { Title, Text } from './Typography'
import { Badge, Badges } from './Badges'
import { useEffect } from 'react'

const reflection = onDesktop`
  -webkit-box-reflect: below 20px -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(250, 250, 250, 0.1)));
`

const PRESENT = new Date()
const createDate = (year, month, day) => new Date(`${year}-${month}-${day}`)

const aDay = 1000 * 60 * 60 * 24
const aMonth = aDay * 30.5

const timelineHorizontalMargin = 0.4
const timelineVerticalMargin = timelineHorizontalMargin * 1.61
const lineAnimationDuration = '3s'
const timelineScale = 0.85

const dateToDays = (date) => {
    const year = date.getFullYear() - 2000
    const month = date.getMonth() + 1
    const day = date.getDate()
    const days = (year - 1) * 365 + (month - 1) * 30.5 + day

    return days
}

const getTimelineItemSize = (item) => {
    const { to = PRESENT, from } = item
    const daysTo = dateToDays(to)
    const daysFrom = dateToDays(from)
    const daysSpan = daysTo - daysFrom

    return daysSpan * timelineScale
}

const getTimelineItemPosition = (item) => {
    let result = getTimelineItemSize({
        from: item[item.anchorDate || 'from'],
        to: PRESENT,
    })
    return result
}

const compareTimelineItems = (useAnchor = false) => (a, b) => {
    const aDate = a[useAnchor ? a.anchorDate || 'from' : 'from']
    const bDate = b[useAnchor ? b.anchorDate || 'from' : 'from']
    return bDate.getTime() - aDate.getTime()
}

const TimelineItem_ = styled(
    React.forwardRef(({ item, inViewport, leaveCount, enterCount, ...props }, ref) => {
        const { y, size, anchorPosition } = item

        return (
            <div
                ref={ref}
                {...props}
                className={classnames(props.className, 'anchor' + capitalize(anchorPosition))}
                style={{ top: y }}
            >
                <Title>{item.position}</Title>
                <Text>{item.company}</Text>
                <Text>{item.description}</Text>

                {item.badges ? (
                    <Badges items={item.badges.replace(/\s*,\s*/g, ',').split(',')} />
                ) : null}
            </div>
        )
    })
)`

  box-shadow: 0px 4px 4px rgba(0,0,0,0.4);

  z-index: 2;

  &:hover {
    z-index: 4;
  }

  ${onDesktop`
    position: absolute;
    top: 0;
  `}
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: ${({ inViewport }) => (inViewport ? 1 : 0)};
  text-align: center;
  background-color: white;
  padding: 0.4em 0.4em;
  border-radius: 0.1em;
  ${reflection}
  ${onMobile`
    margin-bottom: ${timelineVerticalMargin}em;
  `}
  ${onDesktop`
    ${({ item }) => (item.column == 'left' ? 'right' : 'left')}: 50%;
    transform: translate(0, calc(-50% + ${({ inViewport }) => (inViewport ? 0 : 10)}%));
    &.anchorTop {
      transform: translate(0, calc(-0% + ${({ inViewport }) => (inViewport ? 0 : 10)}%));
    }
    &.anchorBottom {
      transform: translate(0, calc(-100% + ${({ inViewport }) => (inViewport ? 0 : 10)}%));
    }
    margin-${({ item }) =>
        item.column == 'left' ? 'right' : 'left'}: ${timelineHorizontalMargin}em;
  `}
  &:last-child {
    margin-bottom: 0px;
  }
  ${onDesktop`
    max-width: 8em;
    &:before {
      position: absolute;
      top: 50%;
      ${({ item }) => (item.column == 'left' ? 'right' : 'left')}: 0;
      transform: translate(${({ item }) => (item.column == 'left' ? 50 : -50)}%, -50%) rotate(${({
      item,
  }) => (item.column == 'left' ? -45 : 135)}deg);
      content: '';
      display: block;
      height: 0.2em;
      width: 0.2em;
      background-color: white;
      border-radius: 15%;
    }
    &:after {
      position: absolute;
      top: 50%;
      bottom: auto;
      ${({ item }) => (item.column == 'left' ? 'right' : 'left')}: ${timelineHorizontalMargin *
      -1}em;
      transform: translate(${({ item }) => (item.column == 'left' ? 50 : -50)}%, -50%);
      content: '';
      display: block;
      border-radius: 100%;
      background-color: white;
      height: 0.12em;
      width: 0.12em;
      z-index: 2;
    }
    &.anchorTop:before, &.anchorTop:after {
      top: ${timelineHorizontalMargin}em;
      bottom: auto;
    }
    &.anchorBottom:before, &.anchorBottom:after {
      top: auto;
      bottom: ${timelineHorizontalMargin}em;
    }
    &.anchorTop:after {
      transform: translate(${({ item }) => (item.column == 'left' ? 50 : -50)}%, -50%);
    }
    &.anchorBottom:after {
      transform: translate(${({ item }) => (item.column == 'left' ? 50 : -50)}%, -100%);
    }
  `}
`

const delayedComponent = (getComponent) => (props) => {
    const [canRender, setCanRender] = useState(false)

    useEffect(() => {
        setCanRender(true)
    }, [])

    if (canRender) {
        const Component = getComponent()
        return <Component {...props} />
    } else {
        return null
    }
}

const TimelineItem = delayedComponent(
    () =>
        handleViewport(
            ({ inViewport, innerRef, forwardedRef, ...props }) => {
                return <TimelineItem_ {...props} ref={forwardedRef} inViewport={inViewport} />
            },
            {
                root: document.querySelector('body'),
                threshold: 0.2,
                rootMargin: '-10px',
            }
        ),
    100
)

const TimelineContent = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -2px;
    ${onMobile`
    padding-top: ${timelineVerticalMargin * 2}em;
    max-height: 100% !important;
    height: 100% !important;
    & > :last-child {
      margin-bottom: 4em;
    }
  `}
    ${onDesktop`
    transition: max-height ${lineAnimationDuration} ease 0.3s;
  `}
  position: relative;
    &:before {
        z-index: 0;
        content: '';
        display: block;
        position: absolute;
        top: 0em;
        left: calc(50% - 1px);
        bottom: 0;
        background-color: white;
        width: 2px;
        height: 100%;
    }
`

const TimelineTitle = styled.h3`
    background-color: white;
    border-radius: 0.3em;
    display: inline-block;
    padding: 0.5em 1em;
    margin: 0 auto;
    text-align: center;
    ${onMobile`
    font-size: 0.4em;
  `}
    ${onDesktop`
    font-size: 0.3em;
    transition: opacity 0.3s ease ${lineAnimationDuration};
    opacity: ${({ visible = true }) => (visible ? 1 : 0)};
  `}
`

const TimelineYear = styled(({ item, ...props }) => {
    const { year, placedAs = [] } = item
    const y = getTimelineItemPosition({
        from: !isEmpty(placedAs) ? createDate(...placedAs) : createDate(year, 1, 1),
    })

    return (
        <span {...props} style={{ top: y }}>
            {year}
        </span>
    )
})`
    z-index: 2;
    background-color: white;
    padding: 0.2em 0.4em;
    border-radius: 0.5em;
    ${onMobile`
    display: block;
    margin: 0 auto;
    font-size: 0.4em;
    margin-bottom: ${timelineVerticalMargin * 2}em;
  `}
    ${onDesktop`
    position: absolute; left: 50%; top: 0;
    font-size: 0.2em;
    transform: translate(-50%, 50%);
    z-index: 10;
  `}
`

export const Timeline = styled(({ timeline, visible, ...props }) => {
    const sorted = timeline.sort(compareTimelineItems(true))
    const oldest = sorted[sorted.length - 1]
    const newest = sorted[0]

    const conciliateColumnItems = (topItem, bottomItem) => {
        if (topItem.anchorPosition == 'middle') {
            if (bottomItem.y - topItem.y < 200) {
                topItem.anchorPosition = 'bottom'
                bottomItem.anchorPosition = 'top'
            } else if (bottomItem.y - topItem.y < 400) {
                topItem.anchorPosition = 'bottom'
            }
        } else if (topItem.anchorPosition == 'bottom' || topItem.anchorPosition == 'top') {
            if (bottomItem.y - topItem.y < 200) {
                bottomItem.anchorPosition = 'top'
            }
        }
    }

    const timelineWithYears = timeline.reduce(
        (state, item, index) => {
            const itemDate = item[item.anchorDate || 'from']

            while (itemDate.getFullYear() < state.currentYear) {
                const yearItem = { type: 'year', year: state.currentYear }
                state.timeline.push(yearItem)
                state.years.push(yearItem)
                state.currentYear -= 1
            }

            item.size = getTimelineItemSize(item)
            item.y = getTimelineItemPosition(item)
            item.anchorPosition = 'middle'

            state.timeline.push(item)
            state.items.push(item)

            if (item.column == 'left') {
                if (state.lastLeftItem) {
                    conciliateColumnItems(state.lastLeftItem, item)
                }
                state.lastLeftItem = item
            } else if (item.column == 'right') {
                if (state.lastRightItem) {
                    conciliateColumnItems(state.lastRightItem, item)
                }

                state.lastRightItem = item
            }

            if (index == timeline.length - 1) {
                const yearItem = { type: 'year', year: state.currentYear }
                state.timeline.push(yearItem)
                state.years.push(yearItem)
            }

            return state
        },
        {
            lastLeftItem: null,
            lastRightItem: null,
            items: [],
            years: [],
            timeline: [],
            firstYearPushed: false,
            currentYear: PRESENT.getFullYear(),
        }
    ).timeline

    const timeSpan =
        getTimelineItemSize({
            from: new Date(oldest.from.getFullYear(), 1, 1),
            to: PRESENT,
        }) * 1.1

    const columnHeight = timeSpan
    const maxColumnHeight = visible ? columnHeight : 0

    return (
        <div {...props}>
            <TimelineTitle>Present</TimelineTitle>

            <TimelineContent style={{ height: columnHeight, maxHeight: maxColumnHeight }}>
                {timelineWithYears.map((item, key) =>
                    item.type == 'year' ? (
                        <TimelineYear item={item} key={key} />
                    ) : (
                        <TimelineItem item={item} key={key} />
                    )
                )}

                <TimelineYear
                    item={{
                        type: 'year',
                        year: '...',
                        placedAs: [oldest.from.getFullYear() - 1, 9, 15],
                    }}
                />

                <TimelineYear
                    item={{
                        type: 'year',
                        year: '1991',
                        placedAs: [oldest.from.getFullYear() - 1, 6, 1],
                    }}
                />
            </TimelineContent>

            <TimelineTitle visible={visible}>Past</TimelineTitle>
        </div>
    )
})`
    display: flex;
    flex-direction: column;
    padding-top: ${timelineVerticalMargin * 1.61}em;
    width: 100%;
    margin: 0 auto;
    ${onMobile`
    max-width: 12em;
    margin-bottom: 1em;
  `}
    ${onDesktop`
    transition: opacity 0.3s ease;
    opacity: ${({ visible = false }) => (visible ? 1 : 0)};
    pointer-events: ${({ visible = true }) => (visible ? 'all' : 'none')};
    margin-bottom: 4em;
  `}
`

export const TimelineTrigger = styled.p`
    position: relative;
    font-size: 0.3em;
    font-weight: 500;

    cursor: pointer;

    margin: 0 auto;
    padding: 0.8em 2em;

    ${onDesktop`
    transition: opacity 0.3s ease;
    &:hover {
      opacity: 0.5;
    }
  `}
`
