import React, { Component } from 'react'
import styled, { css, onDesktop, onMobile } from './styled-extended'
import { isUndefined } from 'lodash-es'

const borderRadiusA = 1.2
const borderRadiusB = 0.25
const borderRadiusInner = 1.2

const colorA = '#00D49C'

const colorB = '#0385B6'

const reflection = onDesktop`
  -webkit-box-reflect: below 20px -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(250, 250, 250, 0.1)));
`

export const Card = styled.div`
  display: flex;  
  margin: 0 auto;
  background-color: transparent;
  color: rgba(0,0,0, 0.85);
  ${reflection}
  ${onMobile`
    height: calc(100vh - 0.3em);

    @media (orientation: landscape) {
      max-width: 220px;
    }
  `}
  ${onDesktop`
    width: 9.1em;
    height: 4.5em;
    perspective: 40cm;
    &:hover {
      & > *  {
        transition: transform 0.8s ease 0s;
        transform: rotateY(180deg);
      }
    }


    ${({ toggled }) =>
        !isUndefined(toggled)
            ? css`
                  & > * {
                      transition: transform 0.8s ease 0s;
                      transform: rotateY(${toggled ? 180 : 0}deg);
                  }
              `
            : css``}
    
  `}
`

export const CardContent = styled.div`
  position: relative;
  
  width: 100%;
  height: 100%
  text-align: center;
  ${onDesktop`
    transition: transform 0.8s ease 0.3s;
    transform-style: preserve-3d;
  `}
`

const cardLayerStyle = (inverted = false) => css`
    width: 100%;
    height: 100%;
    background-color: white;

    position: relative;
    margin-bottom: -1px;
    ${onMobile`

    ${
        inverted
            ? css`
                  padding: 0em 0.75em 0.5em 0.75em;
              `
            : css`
                  padding: 0.5em 0.75em 0em 0.75em;
              `
    }
    &:first-child {
      height: calc(42% - 0.15em);
      border-top-left-radius: ${borderRadiusB}em;
      border-top-right-radius: ${borderRadiusB}em;
    }
    &:last-child {
      height: calc(58% - 0.15em);
      border-bottom-left-radius: ${borderRadiusB}em;
      border-bottom-right-radius: ${borderRadiusB}em;
    }
  `}
    ${onDesktop`
    padding: 0.5em 0.75em;
    border-radius: 0.5em;
    border-top-${inverted ? 'right' : 'left'}-radius: ${borderRadiusB}em;
    border-top-${inverted ? 'left' : 'right'}-radius: ${borderRadiusA}em;
    border-bottom-${inverted ? 'left' : 'right'}-radius: ${borderRadiusB}em;
    border-bottom-${inverted ? 'right' : 'left'}-radius: ${borderRadiusA}em;
    position: absolute;
    backface-visibility: hidden;
    transform: rotateY(${inverted ? 180 : 0}deg);
  `}
`

export const Front = styled.div`
    ${cardLayerStyle()}
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${onMobile`
    flex-direction: column;
    padding-top: 1em;
    
  `}
    ${onDesktop`
    outline: 1px solid transparent;
    flex-direction: row;

    /* Top left corner */
    background-color: ${colorA};
    border-bottom-right-radius: ${borderRadiusA}em;
    &:before, &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
    }
    /* Bottom right card corner */
    &:before {
      z-index: -2;
      top: 0;
      left: 50%;
      background-color: ${colorB};
      border-top-right-radius: ${borderRadiusA}em;
      border-bottom-right-radius: ${borderRadiusB}em;
    }
    /* Main card color */
    &:after {
      z-index: -1;
      background-color: white;
      border-top-left-radius: ${borderRadiusInner}em;
      border-top-right-radius: ${borderRadiusA}em;
      border-bottom-left-radius: ${borderRadiusA}em;
      border-bottom-right-radius: ${borderRadiusInner}em;
    }
  `}
`

export const Back = styled.div`
    ${cardLayerStyle(true)}
    padding-bottom: 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${onMobile`
    justify-content: space-between;
  `}
`
