import React, { Component } from 'react'

import './fonts.css'

import classNames from 'classnames'
import styled from './styled-extended'

const mappings = {
  github: 'github-circled',
  mail: 'mail-alt',
}

const iconName = name => {
  name = name.toLowerCase()
  return mappings[name] ? mappings[name] : name
}

export default styled(props => (
  <i
    {...props}
    className={classNames(`icon-${iconName(props.name)}`, props.className)}
  >
    {props.children}
  </i>
))``
