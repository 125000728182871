import React, { Component } from 'react'
import styled, { css, onDesktop, onMobile, keyframes } from './styled-extended'
import Helmet from 'react-helmet'
import { omit, capitalize, range, isUndefined } from 'lodash-es'
import classnames from 'classnames'

import { Timeline, TimelineTrigger } from './Timeline'
import { Title, BackTitle, Subtitle, Text } from './Typography'
import { Badge, Badges } from './Badges'
import { ContactChannel, ContactChannels } from './ContactChannels'
import { Card, CardContent, Front, Back } from './Card'

import './summary.css'

import profileImage from '../../../static/me.jpeg'

const PRESENT = new Date()

const mobilePadding = 0.3

const upAndDownAnimation = keyframes`
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(20%);
  }
  100% {
    transform: translateY(0%);
  }
`

const Content = styled.div`
    font-size: 1.4cm;
    position: relative;
    -webkit-smooth-scroll: touch;
    ${onMobile`
    padding: ${mobilePadding}em;
    font-size: 7.2vh;
    max-width: 475px;
    margin: 0 auto;
  `}
    ${onDesktop`
    padding-top: 30vh;
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    &:before {
      position: absolute;
      content: '';
      display: block;
      top: 0; right: 0; left: 0; bottom: 0;
    }
  `}
`

const Picture = styled.img`
    border-radius: 100%;
    width: 2.8em;
    height: 2.8em;
`

const FrontInfo = styled.div`
    margin: 0em;
    flex-grow: 1;

    ${onMobile`
    margin-top: 0.4em;
    text-align: center;
  `}

    ${onDesktop`
    margin-left: 0.4em;
  `}
`

const DownArrow = styled.span`
  position: absolute;
  left: 50%;
  bottom: -0.6em;
  ${onDesktop`
    bottom: -0.2em;
  `}


  animation-name: ${upAndDownAnimation};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  &:before {
    content: '';
    display: block;
    transform: translate(-50%, -50%) rotate(45deg);
    border: 5px solid grey;
    border-top-color: transparent;
    border-left-color: transparent;
  }
`

class Summary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            timelineVisible: false,
            cardToggled: undefined,
        }
    }

    showTimeline() {
        if (!this.state.timelineVisible) {
            this.setState({ timelineVisible: true })
        }
    }

    reset() {
        this.setState({ timelineVisible: false })
        this.$card.scrollIntoView({ behavior: 'smooth' })
    }

    toggleCard(newCardToggledValue) {
        const { cardToggled } = this.state

        newCardToggledValue = isUndefined(newCardToggledValue) ? !cardToggled : newCardToggledValue
        console.log('Toggle card', { newCardToggledValue })

        this.setState({
            cardToggled: newCardToggledValue,
        })
    }

    handleTouchStart() {}

    handleTouchMove() {}

    handleTouchEnd() {}

    componentDidMount() {
        this.handleTouchStart = this.handleTouchStart.bind(this)
        this.handleTouchMove = this.handleTouchMove.bind(this)
        this.handleTouchEnd = this.handleTouchEnd.bind(this)
        document.addEventListener('touchstart', this.handleTouchStart, false)
        document.addEventListener('touchmove', this.handleTouchMove, false)
        document.addEventListener('touchend', this.handleTouchEnd, false)
    }

    componentWillUnmount() {
        document.removeEventListener('touchstart', this.handleTouchStart, false)
        document.removeEventListener('touchmove', this.handleTouchMove, false)
        document.removeEventListener('touchend', this.handleTouchEnd, false)
    }

    render() {
        const yearsOfExperience = parseInt(PRESENT.getFullYear() - 2011)

        const { timelineVisible = false, cardToggled } = this.state

        return (
            <Content
                ref={(ref) => (this.$content = ref)}
                timelineVisible={timelineVisible && cardToggled}
                onClick={(e) => {
                    console.log('Click on background', e.target)
                    if (e.target == this.$content) {
                        console.log('Show card front')
                        this.toggleCard(false)
                    }
                }}
            >
                <Card ref={(ref) => (this.$card = ref)} toggled={cardToggled}>
                    <CardContent>
                        <Front
                            onClick={(e) => {
                                if (cardToggled !== true) {
                                    console.log('Show card back')
                                    this.toggleCard(true)
                                }
                                e.stopPropagation()
                            }}
                            onMouseEnter={(e) => {
                                console.log('Mouse enter')
                                if (cardToggled !== true) {
                                    console.log('Show card back')
                                    this.toggleCard(true)
                                }
                                e.stopPropagation()
                            }}
                        >
                            <Picture src={profileImage} />
                            <FrontInfo>
                                <Title>Ary Pablo Batista</Title>
                                <Subtitle>Software Engineer</Subtitle>
                            </FrontInfo>
                        </Front>

                        <Back>
                            <BackTitle>Creative {'&'} Proactive</BackTitle>

                            <ContactChannels>
                                <ContactChannel
                                    icon="twitter"
                                    link="https://twitter.com/arypbatista"
                                />
                                <ContactChannel
                                    icon="linkedin"
                                    link="https://www.linkedin.com/in/arypbatista/"
                                />
                                <ContactChannel
                                    icon="github"
                                    link="https://github.com/arypbatista"
                                />
                                <ContactChannel icon="mail" link="mailto:arypbatista@gmail.com" />
                            </ContactChannels>

                            <Text style={{ textAlign: 'center' }}>
                                Has {yearsOfExperience} years of experience as Full-Stack Software
                                Engineer providing High Quality Software. Great soft skills.
                            </Text>

                            <div style={{ marginBottom: '0.2em' }}></div>

                            <Badges items={['Shopify', 'React', 'Webpack', 'Python', 'Ruby']} />

                            <TimelineTrigger onClick={this.showTimeline.bind(this)}>
                                <span>See my timeline</span>
                                <DownArrow />
                            </TimelineTrigger>
                        </Back>
                    </CardContent>
                </Card>

                <Timeline timeline={this.props.timeline} visible={timelineVisible && cardToggled} />
            </Content>
        )
    }
}

export default Summary
