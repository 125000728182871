import React from 'react'
import { Link } from 'gatsby'
import Navbar from './navigation'
import Footer from './footer'

import Helmet from 'react-helmet'

import {  ThemeProvider } from 'styled-components'
import theme, { GlobalStyle, onDesktop, whiteColorSchema, blackColorSchema } from '../theme'

import { withI18n } from '../i18n'

import { lowerCase } from 'lodash'

import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

const Content = styled.main`
  flex: 1;
  display: block;
  width: 100%;
  position: relative;
`

const LayoutFooter = styled(Footer)`

  margin-top: 7.5rem;

  ${onDesktop`
    margin-top: 18.0rem;
  `}
`

class Layout extends React.Component {

  render() {
    const { location, children, navbarRetainer=true, noNavbar, noFooter } = this.props

    const schema = lowerCase(this.props.schema || 'white')

    let header

    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }

    return (
      <ThemeProvider theme={theme}>

        <GlobalStyle schema={schema} />

        <Wrapper>

          { noNavbar ? null : <Navbar /> }
        
          <Content>
            {children}
          </Content>
        
          { noFooter ? null : <LayoutFooter /> }

        </Wrapper>

      </ThemeProvider>
    )
  }

}

export default withI18n(Layout)
