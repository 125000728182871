import styled, { css } from 'styled-components'

export * from 'styled-components'

export default styled

export const MOBILE_MAX_WIDTH = 767

export const DESKTOP_MIN_WIDTH = MOBILE_MAX_WIDTH + 1

const bind = (f, ...args) => f.bind(null, ...args)

export const withBreakpoint = breakpoint => (...args) => css`
  @media (${breakpoint}) {
    ${css(...args)};
  }
`

export const maxWidth = width => withBreakpoint(`max-width: ${width}px`)
export const minWidth = width => withBreakpoint(`min-width: ${width}px`)

export const onMobile = maxWidth(MOBILE_MAX_WIDTH)
export const onDesktop = minWidth(DESKTOP_MIN_WIDTH)
