import React from 'react'
import styled from 'styled-components'

import Container from './container'

import { kebabCase, isString } from 'lodash'


const Heading = styled.div`
  text-align: center;
  max-width: 58rem;
  margin: 0 auto;
  margin-bottom: 4rem;

  ${({fullwidth}) => fullwidth ? `
    padding: 0 4rem;
  ` : `` }

  h1,h2,h3,p {
    margin: 0;
  }
`

const Content = styled.div`
  display: block;
`

const SectionAnchor = styled.a`
  position: relative;
  top: -12rem;
`

export default styled((props) => {
  const { fullwidth, style, narrow } = props

  return (
    <div className={props.className} style={style}>

      <SectionAnchor id={props.id || kebabCase(props.title)} />

      <Container fullwidth={fullwidth} narrow={narrow}>
      
        {
          props.title ?
            <Heading fullwidth={fullwidth}>
              <h2>{ props.title }</h2>
              {
                props.subtitle ? 
                  isString(props.subtitle) 
                    ? <p>{ props.subtitle }</p>
                    : props.subtitle
                : null
              }
            </Heading>
          : null
        }

        <Content>
          { props.children }

        </Content>

      </Container>

    </div>
  )
})`
  margin-top: 8rem;
  margin-bottom: 12rem;
`