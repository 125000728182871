import React, { Component } from 'react'
import { graphql } from 'gatsby'

import Helmet from 'react-helmet'
import Hero from '../components/hero'
import Page from '../components/page'
import Section from '../components/section'
import ArticlePreview from '../components/article-preview'
import Article from '../components/article'
import styled from 'styled-components'
import { Grid, Cell } from '../components/grid'
import { onMobile, onDesktop } from '../theme'

import { FaLinkedin, FaGithub, FaTwitter } from 'react-icons/fa'

import tr, { languageContentFilter } from '../i18n'
import Summary from '../old/Summary/Summary'
import { get, isEmpty, filter } from 'lodash-es'

const Root = styled.div`
    * {
        box-sizing: border-box;
    }
`

const IndexPage = (props) => {
    const siteTitle = get(props, 'data.site.metadata.title')

    const jobPositions = filter(get(props, 'data.jobPositions.nodes'), (i) => i.locale == 'en-US')
    const education = filter(get(props, 'data.education.nodes'), (i) => i.locale == 'en-US')

    const timeline = [
        ...jobPositions.map((item, index) => ({
            from: new Date(item.startDate),
            to: item.endDate ? new Date(item.endDate) : undefined,
            type: 'job-position',
            position: item.position,
            company: item.company,
            description: get(item, 'abstract.text'),
            badges: !isEmpty(item.technologies) ? item.technologies.join(', ') : '',
        })),
        ...education.map((item, index) => ({
            from: new Date(item.startDate),
            to: item.endDate && new Date(item.endDate),
            type: 'education',
            position: item.degree,
            company: item.school,
            description: get(item, 'abstract.text'),
            badges: '',
        })),
    ]

    console.log({ jobPositions, education })

    return (
        <Page location={props.location} noNavbar noFooter>
            <Root>
                <Helmet>
                    <meta content="width=device-width, initial-scale=1" name="viewport" />

                    <title>Ary Pablo Batista - Software Developer</title>
                    <meta
                        name="description"
                        content="Software developer with 8 years of experience as Full-Stack Developer providing High Quality Software"
                    />
                    <meta name="robots" content="Index,Follow" />

                    <link
                        href="https://fonts.googleapis.com/css?family=Raleway:100,300,500"
                        rel="stylesheet"
                    />

                    <style>{`
                        html, body {
                        font-family: 'Raleway', sans-serif;
                        font-smooth: antialiased;

                        color: var(--body-text-color);
                        background: var(--body-color);
                        }
                    `}</style>
                </Helmet>
                <Summary
                    timeline={timeline
                        .map((item, index) => ({
                            ...item,
                            anchorDate: item.type == 'education' ? 'to' : 'from',
                        }))
                        .sort((a, b) => {
                            const aDate = a[a.anchorDate]
                            const bDate = b[b.anchorDate]
                            return !bDate ? a : !aDate ? b : bDate.getTime() - aDate.getTime()
                        })
                        .map((item, index) => ({
                            ...item,
                            column: index % 2 == 0 ? 'left' : 'right',
                        }))}
                />
            </Root>
        </Page>
    )
}

export default IndexPage

export const pageQuery = graphql`
    query HomeQuery {
        ...globalFragment
        ...allEducationFragment
        ...allJobPositionFragment
    }
`
