import React, { Component } from 'react'
import styled, { css, onDesktop, onMobile } from './styled-extended'
import { capitalize } from 'lodash-es'

const getBadgeColor = props => {
  const { item } = props
  const colors = {
    shopify: '#95BF47',
    react: '#5ED3F3',
    ruby: '#AF0F01',
    webpack: '#1B74BA',
    python: '#FDDF08',
  }

  const color = colors[item.toLowerCase()] ? colors[item.toLowerCase()] : 'grey'

  return color
}

export const Badge = styled(props => (
  <li {...props} style={{ backgroundColor: getBadgeColor(props) }}>
    {props.item}
  </li>
))`
  border-radius: 0.35em;
  min-width: 3em;
  padding: 0.4em 0.7em;
  margin: 0.25em 0.25em;
  font-size: 0.25em;
  background-color: grey;
  display: inline-block;
  color: white;
  /*
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  */

  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
`

export const Badges = styled(props => (
  <ul {...props}>
    {props.items.map((item, key) => (
      <Badge key={key} item={item} />
    ))}
  </ul>
))`
  padding-top: 0px;
  padding-left: 0px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  ${onMobile`
    max-width: 75%;
    font-size: 1.1em;
    margin: 0.25em auto;
  `}
  ${onDesktop`
    margin: 0em 0;
  `}
`
