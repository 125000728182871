import React from 'react'

import { identity } from 'lodash'

import Layout from './layout'
import Helmet from 'react-helmet'

const Page = (props) => {
  const { navbarRetainer = false, title, siteTitle, schema, noNavbar, noFooter } = props
  
  return (
    <Layout location={props.location} schema={schema} navbarRetainer={navbarRetainer} noNavbar={noNavbar} noFooter={noFooter}>
          
      <Helmet title={`${[title, siteTitle].filter(identity).join(' | ')}`} />

      { props.children }

    </Layout>
  )
}

export default Page
