import React, { Component } from 'react'
import styled, { css, onDesktop, onMobile } from './styled-extended'
import Icon from './Icon'

const iconColor = '#0385B6'
const iconHoverColor = '#00D49C'

export const ContactChannels = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0.5em;
  display: flex;
  justify-content: center;
  order: -1;
  ${onMobile`
    margin-bottom: 0em;
  `}
  ${onDesktop`
    flex-direction: column;
    position: absolute;
    right: 0.35em;
    top: 0.4em;
  `}
`

export const ContactChannel = styled(props => (
  <li {...props}>
    <a href={props.link} target="_blank">
      <Icon name={props.icon} />
    </a>
  </li>
))`
  margin-bottom: 0.2em;
  a {
    color: ${iconColor};
    transition: color 0.3s ease;
  }
  a:hover {
    color: ${iconHoverColor};
  }
`
