import React from 'react'
import { Link } from 'gatsby'
import styles from './navigation.module.css'

import tr from '../i18n'

export default () => (
  <nav role="navigation">
    <ul className={styles.navigation}>
      <li className={styles.navigationItem}>
        <Link to="/">{tr('Home')}</Link>
      </li>
      <li className={styles.navigationItem}>
        <Link to="/blog/">{tr('Blog')}</Link>
      </li>
    </ul>
  </nav>
)
