import React from 'react'
import styled from 'styled-components'

import { onDesktop } from '../theme'


export default styled.div`
  box-sizing: border-box; 
  
  max-width: ${({ fullwidth, narrow }) => fullwidth ? '100%' : narrow ? '96rem' :'192.0rem'};
  padding: 0 ${({ fullwidth }) => fullwidth ? '0px' : '2.1rem'};
  width: 100%;

  ${onDesktop`
    padding: 0 ${({ fullwidth }) => fullwidth ? '0px' : '6.2rem'};
    width: 100%;
  `}
  
  
  margin: 0 auto;
`