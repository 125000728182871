import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import styled from 'styled-components'

const Tag = styled.p`
  color: #A0A0A0;
  text-decoration: none;
  display: inline-block;
  padding: .33333rem .5rem;
  line-height: 1;
  border-radius: 2px;
  border: 1px solid #A0A0A0;
  margin-right: .5em;
`

export default ({ article }) => (
  <div>
    {
      article.heroImage && article.heroImage.fluid ?
        <Img alt="" fluid={article.heroImage.fluid} />
      : null
    }

    <h3>
      <Link to={`/blog/${article.slug}`}>{article.title}</Link>
    </h3>
    <small>{article.publishDate}</small>
    <p
      dangerouslySetInnerHTML={{
        __html: article.body.childMarkdownRemark.html,
      }}
    />
    {article.tags && article.tags.map(tag => (
      <Tag key={tag}>
        {tag}
      </Tag>
    ))}
  </div>
)
