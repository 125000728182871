import React from 'react'
import { createGlobalStyle, css } from 'styled-components'
import { lowerCase, get, kebabCase } from 'lodash'

export const breakpoints = {
    xs: 0,
    sm: 480,
    md: 800,
    lg: 960,
}

export const respondTo = Object.keys(breakpoints).reduce((accumulator, label) => {
    accumulator[label] = (...args) => css`
        @media (min-width: ${breakpoints[label] + 'px'}) {
            ${css(...args)};
        }
    `
    return accumulator
}, {})

export const onMobile = (...args) => css`
  @media (max-width: calc(${breakpoints.md + 'px'} - 1px)) {
    ${css(...args)};
  }
`

export const onDesktop = (...args) => css`
    @media (min-width: ${breakpoints.md + 'px'}) {
        ${css(...args)};
    }
`

const getMargin = (theme, margin) => get(theme.margins, kebabCase(margin), theme.margins['small'])

const getMobileMargin = (theme, margin) =>
    get(theme.mobile.margins, kebabCase(margin), theme.mobile.margins['small'])

export const marginMixin = () => css`
    ${({ theme, margin, marginTop, marginTopMobile, marginTopDesktop }) =>
        margin || marginTop || marginTopMobile || marginTopDesktop
            ? css`
                  margin-top: ${getMobileMargin(theme, marginTopMobile || marginTop)}rem;

                  ${onDesktop`
        
        margin-top: ${getMargin(theme, marginTopDesktop || marginTop)}rem;
      `}
              `
            : css``}

    ${({ theme, margin, marginBottom, marginBottomMobile, marginBottomDesktop }) =>
        margin || marginBottom || marginBottomMobile || marginBottomDesktop
            ? css`
                  margin-bottom: ${getMobileMargin(theme, marginBottomMobile || marginBottom)}rem;

                  ${onDesktop`
        margin-bottom: ${getMargin(theme, marginBottomDesktop || marginBottom)}rem;
      `}
              `
            : css``}
`

const fontFace = ({ family, filename, weight = 400, style = 'normal' }) => css`
  @font-face {
    font-family: '${family}';
    src: url('/fonts/${filename}.eot');
    src: local('${filename
        .match(/([A-Z][a-z]+|\-)/g)
        .join(' ')
        .replace('-', '')}'), local('${filename}'),
        url('/fonts/${filename}.eot?#iefix') format('embedded-opentype'),
        url('/fonts/${filename}.woff2') format('woff2'),
        url('/fonts/${filename}.woff') format('woff'),
        url('/fonts/${filename}.ttf') format('truetype');
    font-weight: ${weight};
    font-style: ${style};
  }
`

const styleVariables = css`
    :root {
        --black: #000000;
        --white: #ffffff;

        --font-body: 'Helvetica Now Text', 'Open Sans', sans-serif;
        --font-heading: 'Helvetica Now Display', 'Open Sans', sans-serif;
        --font-emphasis: 'Times New Roman', serif;
    }
`

const generalStyling = css`

  ${fontFace({
      family: 'Helvetica Now Display',
      filename: 'HelveticaNowDisplay-Medium',
      weight: 500,
  })}

  ${fontFace({
      family: 'Helvetica Now Text',
      filename: 'HelveticaNowText-Medium',
      weight: 600,
  })}

  ${fontFace({
      family: 'Helvetica Now Text',
      filename: 'HelveticaNowText-Medium',
      weight: 500,
  })}

  ${fontFace({
      family: 'Helvetica Now Text',
      filename: 'HelveticaNowText-Regular',
      weight: 400,
  })}

  html {
    font-size: 62.5%;
  }

  html, body {
    font-family: var(--font-body);

    font-size: calc(10 / 375 * 100vw); 

    ${onDesktop`
      font-size: calc(10 / 1920 * 100vw); 
    `}
    
    
  }

  body { 
    margin: 0; padding:0;
    font-size: 2.4rem;
  }
  
  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;

      .gatsby-image-wrapper {
        transition: opacity 0.3s ease;
        opacity: 0.75;
      }
    }
  }

  body {
    background-color: var(--background-color);
    color: var(--body-color);
  }

  a {
    color: var(--body-color);
  }
`

const BlackSchema = createGlobalStyle`
  
  ${styleVariables}

  :root {
    --background-color: var(--black);
    --body-color: var(--white);
  }

  ${generalStyling}  
`

const WhiteSchema = createGlobalStyle`

  ${styleVariables}

  :root {
    --background-color: var(--white);
    --body-color: var(--black);
  }

  ${generalStyling}
`

export const GlobalStyle = (props) =>
    lowerCase(props.schema) == 'black' ? <BlackSchema {...props} /> : <WhiteSchema {...props} />

export default {
    navbar: {
        height: 12.8, // rem
    },
    navbarMobile: {
        height: 5.5, // rem
    },

    margins: {
        'extra-small': 9.0, // TBD
        small: 15.0,
        medium: 22.0,
        large: 27.0,
        'extra-large': 35.6, // TBD
    },

    mobile: {
        margins: {
            'extra-small': 2.7, // TBD
            small: 2.7,
            medium: 5.4,
            large: 10.0,
            'extra-large': 17.6, // TBD
        },
    },

    flexboxgrid: {
        // Defaults
        gridSize: 12, // columns
        gutterWidth: 6, // rem
        outerMargin: 0, // rem
        mediaQuery: 'only screen',
    },
}
