import React, { Component } from 'react'
import styled, { css, onDesktop, onMobile } from './styled-extended'

export const Title = styled.h1`
  margin: 0 0 0.25em 0;
  font-size: 0.46em;
  font-weight: 500;
  letter-spacing: 0.8px;
`

export const BackTitle = styled(Title)`
  ${onMobile`
    display: none;
  `}
`

export const Subtitle = styled.h2`
  margin: 0;
  font-size: 0.34em;
  font-weight: 300;
  letter-spacing: 0.5px;
`

export const Text = styled.p`
  margin: 0;
  font-size: 0.3em;
  font-weight: 300;
  letter-spacing: 0.5px;
  margin: 0.4em auto 0.4em auto;
  ${onMobile`
    margin: 0.8em auto;
  `}
  max-width: 22em;
`
